<template>
    <v-container class="amp-module-page">
        <amp-distribution-result
            v-if="pressRelease.background_distribution_result"
            :result="pressRelease.background_distribution_result"
            class="mb-8"
        />

        <a-form ref="form" :auto-submit="!isModuleBusy" @auto-submit="autoSave">
            <amp-row-first>
                <template #input>
                    <a-alert
                        v-for="(issue, i) in publishIssues"
                        :key="i"
                        :message="issue.message"
                    />
                    <a-text-input
                        ref="headline"
                        v-model="pressRelease.headline"
                        label="Headline"
                        rules="required|max:80"
                        rows="1"
                        textarea
                        auto-grow
                        observed
                    />
                </template>

                <template #validation>
                    <amp-validation :input="$refs.headline">
                        <a-alert
                            :type="pressRelease.headline ? 'success' : 'info'"
                        >
                            <span>
                                Headline must be fewer than 80 characters.
                                Current:
                                {{
                                    pressRelease.headline
                                        ? characterCount(pressRelease.headline)
                                        : 0
                                }}
                            </span>
                        </a-alert>
                    </amp-validation>
                </template>
            </amp-row-first>

            <amp-row>
                <template #input>
                    <a-text-input
                        ref="summary"
                        v-model="pressRelease.summary"
                        label="Summary"
                        rules="required|words:0,45"
                        textarea
                        rows="4"
                        auto-grow
                        observed
                    />
                </template>

                <template #validation>
                    <amp-validation :input="$refs.summary">
                        <a-alert
                            :type="pressRelease.summary ? 'success' : 'info'"
                            :message="
                                actualWordsCount(
                                    pressRelease.summary,
                                    0,
                                    45,
                                    'Summary'
                                )
                            "
                        />
                    </amp-validation>
                </template>
            </amp-row>

            <amp-row>
                <template #input>
                    <div class="mb-1">Media</div>

                    <div class="pb-8">
                        <media-preview-list
                            :media-resources="pressRelease.media_resources"
                            :user-id="pressRelease.user_id"
                            class="pa-1"
                            module-title="News Article"
                            @mediaFiles="onMediaFilesUpdate"
                        />

                        <validation-provider
                            ref="media_resources"
                            name="media_resources"
                            slim
                        >
                            <input
                                v-model="pressRelease.media_resources"
                                type="hidden"
                            />
                        </validation-provider>
                    </div>
                </template>
            </amp-row>

            <amp-row>
                <template #input>
                    <a-content-editor
                        v-model="pressRelease.content"
                        label="Content"
                        rules="required"
                    />
                </template>

                <template #validation>
                    <content-editor-validations
                        :content="pressRelease.content"
                        rules="wordCountBetween:300,1000|linkCount:html|nonFirstPerson"
                    />
                </template>
            </amp-row>

            <amp-row-last />
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Route, NavigationGuardNext } from 'vue-router';
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import {
    Endpoint,
    WordsCounter,
    ValidateLinksReachability,
    InfoFieldMixin
} from '@/mixins';
import { InjectReactive, Watch } from '@/utils/decorators';
import { areMediaResourcesEqual } from '@/utils/helpers';

import { AForm } from '@/components/AForm';
import { AAlert } from '@/components/AAlert';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { AContentEditor } from '@/components/AForm/Inputs/AContentEditor';
import { ContentEditorValidations } from '@/components/ContentEditor';
import { MediaPreviewList } from '@/components/Media';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast,
    AmpValidation
} from '@/components/AmpModule/AmpPage';

import { AmpDistributionResult } from '@/components/AmpModule/AmpDistributionResult';

import type { PressRelease } from '@/types/PressRelease';
import type { AmpModules } from '@/types/Announcement';
import type { FormMediaResource } from '@/types/Media';
import type { ModuleLink } from '@/types/ModuleLink';
import type { MediaResource } from '@/types/MediaResource';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        AForm,
        ATextInput,
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AmpValidation,
        MediaPreviewList,
        AContentEditor,
        AAlert,
        ContentEditorValidations,
        AmpDistributionResult
    },
    computed: {
        ...mapGetters('user', ['isEditor'])
    }
})
export default class NewsArticle extends mixins(
    Endpoint,
    WordsCounter,
    ValidateLinksReachability,
    InfoFieldMixin
) {
    isEditor!: boolean;

    $refs!: {
        form: InstanceType<typeof AForm>;
        headline: InstanceType<typeof ATextInput>;
        summary: InstanceType<typeof ATextInput>;
        media_resources: InstanceType<typeof ValidationProvider>;
    };

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @InjectReactive({
        from: 'isModuleBusy',
        default() {
            return false;
        }
    })
    isModuleBusy!: boolean;

    @Watch('modules')
    onModulesChanged() {
        this.getData();
    }

    @Watch('pressRelease.media_resources', { deep: true })
    onMediaResourcesChange(a: MediaResource[], b: MediaResource[]) {
        if (this.isReadyForChanges && !areMediaResourcesEqual(a, b)) {
            this.$refs.media_resources.setFlags({
                dirty: true,
                changed: true,
                touched: true
            });
        }
    }

    pressRelease: Partial<PressRelease> = {
        media_resources: []
    };

    isSaving = false;
    isReadyForChanges = false;

    endpoint = '/press_releases/edit';

    link: ModuleLink[] = [
        {
            type: 'primary',
            label: 'Review',
            to: this.reviewLink
        }
    ];

    get moduleId() {
        return this.modules?.press_release_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get publishIssues() {
        return this.$store.getters['broadcast/subscribe'](
            `${this.announcementId}-publish-article`
        );
    }

    get reviewLink() {
        return `/announcements/review/${this.announcementId}/article`;
    }

    get isInitialized() {
        return Boolean(this.pressRelease.id);
    }

    onMounted() {
        if (this.moduleId) {
            this.setPrePublishHook();

            this.load();
        } else {
            this.$router.push('/announcements');
        }
    }

    setPrePublishHook(isSet = true) {
        this.$emit('pre-publish', isSet ? this.prePublish.bind(this) : null);
    }

    onData(data: { pressRelease: PressRelease }) {
        if (data.pressRelease) {
            return this.commit(data);
        } else {
            this.review();
        }
    }

    async commit(data: { pressRelease?: PressRelease }) {
        if (data.pressRelease) {
            this.softCommit(data.pressRelease);
            this.protectRoute();
            this.emitLinks();
        }

        await this.setSaved();

        this.isReadyForChanges = true;

        return data;
    }

    softCommit(data: PressRelease) {
        if (this.isInitialized) {
            const softProperties: Array<keyof PressRelease> = [
                'is_editable',
                'is_live',
                'is_publishable',
                'status',
                'status_string'
            ];

            (Object.keys(data) as Array<keyof PressRelease>)
                .filter(key => softProperties.includes(key))
                .forEach(key => {
                    this.$set(this.pressRelease, key, data[key]);
                });
        } else {
            this.pressRelease = data;
        }
    }

    getDataToSave() {
        return {
            id: this.pressRelease.id,
            headline: this.pressRelease.headline,
            summary: this.pressRelease.summary,
            media_resources: this.pressRelease.media_resources,
            content: this.pressRelease.content
        };
    }

    save(foreground = true) {
        this.setSaving();

        return this.setData()
            .then(() => {
                if (foreground) {
                    this.onSave();
                }
            })
            .catch(() => {
                if (foreground) {
                    this.notifyError();
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    async autoSave() {
        return this.setData().catch(error => {
            if (!error.isIntercepted) {
                this.$store.dispatch('notification/error', error);
            }
        });
    }

    async setData() {
        return this.$http
            .post(this.sourceUrl, this.getDataToSave())
            .then(({ data }) => data)
            .then(({ data }) => this.commit(data));
    }

    async onSave() {
        const isValid = await this.revalidate();

        if (isValid) {
            this.review();
        }
    }

    async revalidate() {
        // reset existing errors
        this.$store.dispatch(
            'broadcast/reset',
            `${this.announcementId}-publish-article`
        );

        const isValid = await this.$refs.form.validate();

        if (!isValid) {
            this.notifyInvalid();
        }

        return isValid;
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    async setSaved() {
        return this.$refs.form.reset();
    }

    review() {
        this.$router.push(this.reviewLink);
    }

    beforeRouteLeave(_to: Route, _from: Route, next: NavigationGuardNext) {
        this.setPrePublishHook(false);

        if (this.$refs.form.isDirty) {
            return this.save(false).then(() => next());
        } else {
            return next();
        }
    }

    protectRoute() {
        if (!this.pressRelease.is_editable) {
            this.review();
        }
    }

    emitLinks() {
        this.$emit('links', this.link);
    }

    prePublish() {
        if (!this.$refs.form?.isDirty) {
            return this.revalidate();
        }

        this.setSaving();

        return this.setData()
            .then(() => this.revalidate())
            .catch(() => {
                this.notifyError();

                return false;
            })
            .finally(this.setSaving.bind(this, false));
    }

    notifyInvalid() {
        this.$store.dispatch(
            'notification/info',
            'News Article saved successfully. Please check the form for errors.'
        );
    }

    notifyError() {
        this.$store.dispatch(
            'notification/error',
            'Unable to save News Article. Please check the form for errors.'
        );
    }

    onMediaFilesUpdate(resources: FormMediaResource[]) {
        this.pressRelease.media_resources = [...resources];

        this.$refs.media_resources.setFlags({
            dirty: true,
            changed: true,
            touched: true
        });
    }
}
</script>
